class VideoTranscript {

	constructor(){
		this.init();
	}

	init() {

		$("body").on( "click", ".video-caption .is-style-show", function(e) {
			e.preventDefault();
			let video_transcript = $(e.target).parents(".wp-block-block:first").find(".video-transcript:first");
			if(video_transcript[0]){
				video_transcript.toggleClass("open");
				$(e.target).parent().toggleClass("is-style-hide");
				if(video_transcript.hasClass("open")){
					$(e.target).html($(e.target).parent().attr("data-hidetext"));
				}else{
					$(e.target).html($(e.target).parent().attr("data-showtext"));
				}
			}
		});

		$("body").on( "click", ".video-transcript__close", function(e) {
			let show_button = $(e.target).parents(".wp-block-block:first").find(".is-style-hide:first > a");
			if(show_button[0]){
				show_button.trigger("click");
			}
		});
	
	}

};

export default VideoTranscript;