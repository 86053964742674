window.td_overflow = function () {
    return {
        open: false,
        compact: false,
        overflowing: false,
        resizing: false,
        children: [],
        $lastChild: null,
        $list: null,
        init() {
            const $list = this.$refs.list;

            const children = [...$list.children];

            const $first = children.shift();
            const $toggle = children.shift();
            this.$lastChild = children.pop();
            this.children = children.reverse();
            this.$list = $list;

            this.setHasOverflow();
        },
        isOverflowing() {
            const { width, x } = this.$lastChild.getBoundingClientRect();
            const { width: listWidth, x: offset } =
                this.$list.getBoundingClientRect();

            return x + width - offset > listWidth;
        },
        setHasOverflow() {
            const overflows = [];

            this.children.forEach((i) => (i.hidden = true));

            this.$nextTick(() => {
                let shouldHideSubsequent = false;

                for (const el of this.children) {
                    el.hidden = false;

                    const isOffset = this.isOverflowing();

                    overflows.push(isOffset);

                    if (isOffset || shouldHideSubsequent) {
                        el.hidden = true;
                        shouldHideSubsequent = true;
                    }
                }

                this.compact = !overflows.filter((i) => !i).length;
                this.overflowing = overflows.reduce((a, b) => a || b, false);
                this.resizing = false;
            });
        },
        resize() {
            this.open = false;
            this.compact = false;
            this.overflowing = false;
            this.resizing = true;
        },
        endResize() {
            this.setHasOverflow();
        },
        toggle() {
            this.open = !this.open;

            this.$nextTick(() => {
                this.setHasOverflow();
            });
        },
    };
};
