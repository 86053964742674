/**
 *
 * To create new scripts create a partial inside src and write your code there either inside
 * a function or object oriented.
 *
 * Then call it on the relevant page below or in the common function to be run on every page.
 *
 */

//Utilities
import Masonry from "./common/masonry";
import ScrollToError from "./common/scroll-to-error";
import VideoTranscript from "./components/video-transcript";
import CopyButton from "./components/copy-button";
import CareersSearch from "./components/careers-search";
import DatePicker from "./components/date-picker";
import AdvancedCategoryFilter from "./components/advanced-category-filter";

const ROOT = document.documentElement;

window.$ = window.jQuery; // Set JQuery Variable
window.tdScrollLock = (lock) => ROOT.classList.toggle("is-scroll-locked", lock);
window.addEventListener("scroll", () => ROOT.style.setProperty("--td--window--scroll", window.scrollY));

(() => {
    const App = {
        // use the classname hook as the function name
        init() {
            new ScrollToError();
            new Masonry();
            new VideoTranscript();
            new CopyButton();
        },
        ['post-type-archive']() {
            new DatePicker();
            new AdvancedCategoryFilter();
        },
        ['post-type-archive-career_post']() {
            new CareersSearch();
        }
    };

    ['init', ...document.body.classList].forEach(fun => App[fun] ? App[fun]() : null);
})();
