class CopyButton {
    constructor() {
        document.querySelectorAll('.is-style-copy > a').forEach(link => {
            link.addEventListener('click', event => {
                event.preventDefault();

                const text = event.target.href.replace('mailto:', '');

                setTimeout(() => event.target.classList.remove('is-style-copied'), 3000);

                navigator.clipboard.writeText(text)
                    .then(() => event.target.classList.add('is-style-copied'));
                //.catch(() => error.log(text))
                //.finally();
            });
        });
    }
}

export default CopyButton;