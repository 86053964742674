class Masonry {
    constructor() {
        window.addEventListener("mega-menu-opened", ({ detail }) => {
            requestAnimationFrame(() => {
                this.layout([...detail.el.querySelectorAll(".js-masonry")]);
            });
        });

        window.addEventListener("resize", (e) => {
            this.layout([...document.querySelectorAll(".js-masonry")]);
        });
    }
    getHeight(el) {
        return el.getBoundingClientRect().height;
    }
    setProp(el, k, v) {
        return el.style.setProperty(k, v);
    }
    getProp(el, k) {
        return getComputedStyle(el).getPropertyValue(k);
    }
    getColumnStart(el) {
        return this.getProp(el, "--td--mega-menu--column");
    }
    layout(grids) {
        grids = grids.map((grid) => ({
            grid: grid,
            sections: grid.childNodes,
        }));

        let columns = Array(6).fill([]);

        grids.forEach(({ grid, sections }) => {

            const newColumns = columns.map((col, i) => {
                return [...sections].filter(section => {
                    const index = this.getColumnStart(section);
                    return i + 1 == index;
                });
            });

            let tallestColumnHeight = 0;

            newColumns.forEach(column => {
                let sectionOffset = 0;

                column.forEach(section => {
                    this.setProp(section, "--td--mega-menu--offset", sectionOffset);
                    const blockEndMargin = this.getProp(section, 'margin-block-end');
                    sectionOffset = this.getHeight(section) + sectionOffset + parseInt(blockEndMargin);
                });


                if (sectionOffset > tallestColumnHeight) {
                    tallestColumnHeight = sectionOffset;
                }

                sectionOffset = 0;
            });

            this.setProp(grid, "--td--mega-menu--height", tallestColumnHeight);
        });
    }
}

export default Masonry;
