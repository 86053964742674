class FilterTagButton {
    constructor() {
        document.querySelectorAll('.js-career-filter-tag-button').forEach(button => {
            button.addEventListener('click', ({ currentTarget }) => {
                const { name, value } = currentTarget.dataset;
                const input = document.querySelector(`input[name="${name}"][value="${value}"]`);
                if (input) {
                    input.checked = false;
                    currentTarget.form.requestSubmit();
                }
            });
        });
    }
}

export default FilterTagButton;