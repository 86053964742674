import { $, $$ } from '../common/helpers';
import FilterTagButton from './filter-tag-button';

class AdvancedCategoryFilter {
    constructor() {
        new FilterTagButton();

        $$('.js-date-posted-tag-button').forEach(button => {
            button.addEventListener('click', ({ currentTarget }) => {
                const start = $('#easepick-start');
                const end = $('#easepick-end');
                if (start && end) {
                    start.value = '';
                    end.value = '';
                    currentTarget.form.requestSubmit();
                }
            });
        });

    }
}

export default AdvancedCategoryFilter;