// https://easepick.com/
import { $, event } from '../common/helpers';
import { easepick } from "@easepick/core";
import { KbdPlugin } from "@easepick/kbd-plugin";
import { RangePlugin } from "@easepick/range-plugin";
import { PresetPlugin } from "@easepick/preset-plugin";
import { AmpPlugin } from "@easepick/amp-plugin";
const { sprintf } = wp.i18n;
const { css, header, nextMonth, previousMonth, cancel, apply, today, yesterday, last7Days, last30Ddays, thisMonth, lastMonth, clear } = tdI18n.datePicker;

const icon = (icon, size = 20) => sprintf('<span class="material-symbols-rounded size-%s" aria-hidden="true">%s</span>', size, icon);
const screenReaderText = (text) => sprintf('<span class="screen-reader-text">%s</span>', text);

class DatePicker {
    constructor() {
        const easepickPicker = new easepick.create({
            element: $('#easepick-start'),
            grid: 2,
            autoApply: false,
            header,
            css: [
                // this is a URL string registered in the Enqueue.php file
                css,
            ],
            lang: document.documentElement.lang,
            zIndex: 1000,
            locale: {
                nextMonth: sprintf('%s %s', screenReaderText(nextMonth), icon('chevron_right')),
                previousMonth: sprintf('%s %s', screenReaderText(previousMonth), icon('chevron_left')),
                cancel: sprintf('%s %s', screenReaderText(cancel), icon('close', 24)),
                apply: sprintf('%s %s', apply, icon('arrow_right_alt')),
            },
            plugins: [KbdPlugin, RangePlugin, PresetPlugin, AmpPlugin],
            RangePlugin: {
                elementEnd: $("#easepick-end"),
                tooltip: false,
            },
            PresetPlugin: {
                customLabels: [
                    today,
                    yesterday,
                    last7Days,
                    last30Ddays,
                    thisMonth,
                    lastMonth,
                ],
            },
            AmpPlugin: {
                resetButton: true,
                locale: {
                    resetButton: clear
                }
            }
        });

        const { container } = easepickPicker.ui;

        window.tdShowDatePicker = () => {
            easepickPicker.show();
            const { top, left } = easepickPicker.adjustPosition($("#easepick-toggle"));
            container.style.setProperty('--y', top);
            container.style.setProperty('--x', left);
        };

        easepickPicker.on('show', () => {
            container.classList.remove('flipped');
            const { right } = container.getBoundingClientRect();
            container.classList.toggle('flipped', right >= window.innerWidth);
        });

        easepickPicker.on('select', () => event('easepickselect'));
        easepickPicker.on('hide', () => event('easepickhide'));
        easepickPicker.on('clear', () => { easepickPicker.hide(); easepickPicker.trigger('select'); });
    }
}

export default DatePicker;